import loadable from "@loadable/component";

// Dynamically load each page component
const LoadableLogin = loadable(() => import("./pages/Login"));
const LoadableHome = loadable(() => import("./pages/Home"));
const LoadableManageUser = loadable(() => import("./pages/User/ManageUser"));
const LoadableManageRole = loadable(() => import("./pages/User/ManageRole"));
const LoadableManageProfile = loadable(() =>
  import("./pages/User/ManageProfile")
);
const LoadableManageDriver = loadable(() =>
  import("./pages/Employee/ManageDriver")
);
const LoadableManagePacker = loadable(() =>
  import("./pages/Employee/ManagePacker")
);
const LoadableDriverPayout = loadable(() =>
  import("./pages/Employee/DriverPayout")
);
const LoadableManageCustomer = loadable(() =>
  import("./pages/Customer/ManageCustomer")
);
const LoadableManageReceiver = loadable(() =>
  import("./pages/Customer/ManageReceiver")
);
const LoadableCustomerDetails = loadable(() =>
  import("./pages/Customer/Details")
);
const LoadableManageShip = loadable(() => import("./pages/Cruise/ManageShip"));
const LoadableShipVendor = loadable(() =>
  import("./pages/Cruise/ManageShipVendor")
);
const LoadableManageDepo = loadable(() => import("./pages/Cruise/ManageDepo"));
const LoadableManagePort = loadable(() => import("./pages/Cruise/ManagePort"));
const LoadableManageVoyage = loadable(() =>
  import("./pages/Cruise/ManageVoyage")
);
const LoadableVoyageDetails = loadable(() =>
  import("./pages/Cruise/VoyageDetails")
);
const LoadableManageVendorContract = loadable(() =>
  import("./pages/Cruise/ManageContract")
);
const LoadableManageTruck = loadable(() =>
  import("./pages/Master/ManageTruck")
);
const LoadableManageService = loadable(() =>
  import("./pages/Master/ManageService")
);
const LoadableManageCategory = loadable(() =>
  import("./pages/Master/ManageCategory")
);
const LoadableManageUnit = loadable(() => import("./pages/Master/ManageUnit"));
const LoadableManageRepresentative = loadable(() =>
  import("./pages/Master/ManageRepresentative")
);
const LoadableManageInsuranceVendor = loadable(() =>
  import("./pages/Master/ManageInsuranceVendor")
);
const LoadableManageInvoiceAdditionalFee = loadable(() =>
  import("./pages/Master/ManageAdditionalFee")
);
const LoadableManageInvoiceAdditionalFeeTemplate = loadable(() =>
  import("./pages/Master/ManageAdditionalFeeTemplate")
);
const LoadableManagePrice = loadable(() =>
  import("./pages/Master/ManagePrice")
);
const LoadableManageOrders = loadable(() =>
  import("./pages/Orders/ManageOrders")
);
const LoadableManageArrivalReceipt = loadable(() =>
  import("./pages/Orders/ManageArrivalReceipt")
);
const LoadableManageOrderBatch = loadable(() =>
  import("./pages/Orders/ManageOrderBatch")
);
const LoadableManageInventory = loadable(() =>
  import("./pages/Orders/ManageInventory")
);
const LoadableManagePortWaybillOutsideDock = loadable(() =>
  import("./pages/Orders/OutsideDock/ManagePortWaybill")
);
const LoadableManagePortWaybillInsideDock = loadable(() =>
  import("./pages/Orders/InsideDock/ManagePortWaybill")
);
const LoadableManagePackingList = loadable(() =>
  import("./pages/Orders/ManagePackingList")
);
const LoadableManageEventReport = loadable(() =>
  import("./pages/Orders/ManageEventReport")
);
const LoadableOrderDetails = loadable(() =>
  import("./pages/Orders/OrderDetails")
);
const LoadableOrderInvoice = loadable(() => import("./pages/Orders/Invoice"));
const LoadableExportData = loadable(() => import("./pages/ExportData"));
const LoadablePrintOutsideDockPortWaybill = loadable(() =>
  import("./pages/Print/PortWaybill/OutsideDock")
);
const LoadablePrintInsideDockPortWaybill = loadable(() =>
  import("./pages/Print/PortWaybill/InsideDock")
);
const LoadablePrintEventReport = loadable(() =>
  import("./pages/Print/EventReport")
);
const LoadablePrintInvoice = loadable(() => import("./pages/Print/Invoice"));
const LoadableNotFound404 = loadable(() => import("./pages/404"));

const routeConfig = [
  { path: "/login", component: LoadableLogin, requiresAuth: false },
  { path: "/", component: LoadableHome, requiresAuth: true },
  // User
  { path: "/manage/user", component: LoadableManageUser, requiresAuth: true },
  { path: "/manage/role", component: LoadableManageRole, requiresAuth: true },
  // Employee
  {
    path: "/manage/driver",
    component: LoadableManageDriver,
    requiresAuth: true,
  },
  {
    path: "/manage/packer",
    component: LoadableManagePacker,
    requiresAuth: true,
  },
  {
    path: "/manage/driver-payout",
    component: LoadableDriverPayout,
    requiresAuth: true,
  },
  // Customer
  {
    path: "/manage/customer",
    component: LoadableManageCustomer,
    requiresAuth: true,
  },
  {
    path: "/manage/receiver",
    component: LoadableManageReceiver,
    requiresAuth: true,
  },
  {
    path: "/customer/details/:id",
    component: LoadableCustomerDetails,
    requiresAuth: true,
  },
  // Cruise
  {
    path: "/manage/cruise-vendor",
    component: LoadableShipVendor,
    requiresAuth: true,
  },
  {
    path: "/manage/contract",
    component: LoadableManageVendorContract,
    requiresAuth: true,
  },
  { path: "/manage/depo", component: LoadableManageDepo, requiresAuth: true },
  { path: "/manage/port", component: LoadableManagePort, requiresAuth: true },
  { path: "/manage/ship", component: LoadableManageShip, requiresAuth: true },
  {
    path: "/manage/voyage",
    component: LoadableManageVoyage,
    requiresAuth: true,
  },
  {
    path: "/voyage/details/:id",
    component: LoadableVoyageDetails,
    requiresAuth: true,
  },
  // Master
  { path: "/manage/truck", component: LoadableManageTruck, requiresAuth: true },
  {
    path: "/manage/service",
    component: LoadableManageService,
    requiresAuth: true,
  },
  {
    path: "/manage/category",
    component: LoadableManageCategory,
    requiresAuth: true,
  },
  { path: "/manage/unit", component: LoadableManageUnit, requiresAuth: true },
  {
    path: "/manage/representative",
    component: LoadableManageRepresentative,
    requiresAuth: true,
  },
  {
    path: "/manage/insurance-vendor",
    component: LoadableManageInsuranceVendor,
    requiresAuth: true,
  },
  {
    path: "/manage/invoice-additional-fee",
    component: LoadableManageInvoiceAdditionalFee,
    requiresAuth: true,
  },
  {
    path: "/manage/invoice-additional-fee-template",
    component: LoadableManageInvoiceAdditionalFeeTemplate,
    requiresAuth: true,
  },
  {
    path: "/manage/price",
    component: LoadableManagePrice,
    requiresAuth: true,
  },
  // Orders
  {
    path: "/manage/orders",
    component: LoadableManageOrders,
    requiresAuth: true,
  },
  {
    path: "/manage/arrival-receipt",
    component: LoadableManageArrivalReceipt,
    requiresAuth: true,
  },
  {
    path: "/manage/inventory",
    component: LoadableManageInventory,
    requiresAuth: true,
  },
  {
    path: "/manage/order-batch",
    component: LoadableManageOrderBatch,
    requiresAuth: true,
  },
  {
    path: "/manage/packing-list",
    component: LoadableManagePackingList,
    requiresAuth: true,
  },
  {
    path: "/order/details/:id",
    component: LoadableOrderDetails,
    requiresAuth: true,
  },
  {
    path: "/manage/event-report",
    component: LoadableManageEventReport,
    requiresAuth: true,
  },
  {
    path: "/invoice",
    component: LoadableOrderInvoice,
    requiresAuth: true,
  },
  // Outside Dock
  {
    path: "/manage/outside-dock/port-waybill",
    component: LoadableManagePortWaybillOutsideDock,
    requiresAuth: true,
  },
  // Inside Dock
  {
    path: "/manage/inside-dock/port-waybill",
    component: LoadableManagePortWaybillInsideDock,
    requiresAuth: true,
  },
  // User Setting
  {
    path: "/manage/profile",
    component: LoadableManageProfile,
    requiresAuth: true,
  },
  {
    path: "/export-data",
    component: LoadableExportData,
    requiresAuth: true,
  },
  //Print
  {
    path: "/print/outside-dock/port-waybill/:id",
    component: LoadablePrintOutsideDockPortWaybill,
    requiresAuth: true,
  },
  {
    path: "/print/inside-dock/port-waybill/:id",
    component: LoadablePrintInsideDockPortWaybill,
    requiresAuth: true,
  },
  {
    path: "/print/event-report/:id",
    component: LoadablePrintEventReport,
    requiresAuth: true,
  },
  {
    path: "/print/invoice/:id",
    component: LoadablePrintInvoice,
    requiresAuth: true,
  },
  // Catch-all for 404
  { path: "*", component: LoadableNotFound404, requiresAuth: true },
];

export default routeConfig;
